#cc-main {
  font-family: inherit;
  color-scheme: dark;
  --cc-bg: #1e1e1e;
  --cc-primary-color: #ffffff;
  --cc-secondary-color: #bfbfbf;
  --cc-btn-primary-bg: #ffffff;
  --cc-btn-primary-color: #000000;
  --cc-btn-primary-hover-bg: #e6e6e6;
  --cc-btn-primary-hover-color: #000000;
  --cc-btn-secondary-bg: #2e2e2e;
  --cc-btn-secondary-color: #ffffff;
  --cc-btn-secondary-hover-bg: #3e3e3e;
  --cc-btn-secondary-border-color: transparent;
  --cc-btn-secondary-hover-border-color: transparent;
  --cc-btn-secondary-hover-color: #ffffff;
  --cc-cookie-category-block-bg: #2e2e2e;
  --cc-cookie-category-block-border: #1e1e1e;
  --cc-cookie-category-block-hover-bg: #3e3e3e;
  --cc-cookie-category-block-hover-border: #3e3e3e;
  --cc-cookie-category-expanded-block-hover-bg: #3e3e3e;
  --cc-cookie-category-expanded-block-bg: #2e2e2e;
  --cc-toggle-readonly-bg: rgba(46, 46, 46, 0.32);
  --cc-overlay-bg: rgba(0, 0, 0, 0.9) !important;
  --cc-toggle-on-knob-bg: var(--cc-bg);
  --cc-toggle-readonly-knob-bg: var(--cc-cookie-category-block-bg);
  --cc-separator-border-color: #1e1e1e;
  --cc-footer-border-color: #1e1e1e;
  --cc-footer-bg: #1e1e1e;
  --cc-toggle-readonly-bg: #3e3e3e;
  --cc-toggle-off-bg: #1e1e1e;
  --cc-toggle-on-bg: #ffd4d4;
}
